import augurElementsMessages from 'common/dist/messages/augurs.elements';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  CampaignOptimizationCommunicationsConfig,
  ConfigCampaignOptimizationCommunicationsConfig,
} from './type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import {
  ElementOptionComponent,
  ElementOptionType,
} from '../../common/ElementOptionComponent';
import { ConfigPageElementForm } from '../../../../../pages/augur/utils/config.form';
import { ConfigFormComponentProps } from '../../../common/utils';
import styles from '../../../../element-configuration/styles.module.scss';
import { useIntl } from 'react-intl';

const CampaignOptimizationCommunicationsConfigFormComponent: FC<
  ConfigFormComponentProps<CampaignOptimizationCommunicationsConfig>
> = ({ configValue, onConfigChange, elements }) => {
  const { control, getValues } =
    useForm<ConfigCampaignOptimizationCommunicationsConfig>({
      defaultValues: configValue,
    });

  const intl = useIntl();

  const updateConfig = () => {
    const config = getValues();
    onConfigChange(config);
  };

  const channelOptions: ElementOptionType[] = elements
    .filter(
      (element) =>
        element.type === SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CHANNELS
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  const propensityOptions: ElementOptionType[] = elements
    .filter(
      (element) =>
        element.type ===
        SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_PROPENSITIES
    )
    .map((element: ConfigPageElementForm) => ({
      ...element,
      label: element.title,
      value: element.uuid,
    }));

  return (
    <div className={styles.verticalConfigLayout}>
      <Controller
        name='channels.elementUuid'
        control={control}
        render={({ field }) => (
          <DropdownSelectInput<ElementOptionType, false>
            onChange={(option) => {
              field.onChange(option?.uuid);
              updateConfig();
            }}
            value={channelOptions.find((option) => option.uuid === field.value)}
            label={intl.formatMessage(augurElementsMessages.channelsLabel)}
            placeholder={intl.formatMessage(
              augurElementsMessages.selectChannelElement
            )}
            options={channelOptions}
            customComponents={{ Option: ElementOptionComponent }}
          />
        )}
      />
      <Controller
        name='propensities.elementUuid'
        control={control}
        render={({ field }) => (
          <DropdownSelectInput<ElementOptionType, false>
            onChange={(option) => {
              field.onChange(option?.uuid);
              updateConfig();
            }}
            value={propensityOptions.find(
              (option) => option.uuid === field.value
            )}
            label={intl.formatMessage(augurElementsMessages.propensitiesLabel)}
            placeholder={intl.formatMessage(
              augurElementsMessages.selectPropensitiesElement
            )}
            options={propensityOptions}
            customComponents={{ Option: ElementOptionComponent }}
          />
        )}
      />
    </div>
  );
};

export default CampaignOptimizationCommunicationsConfigFormComponent;
