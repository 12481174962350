import { defineMessages } from 'react-intl';

export default defineMessages({
  elementInvalid: {
    id: 'augur.elements.invalid',
    defaultMessage:
      'Element is invalid. Open the sidebar to fix the invalid field(s).',
  },
  elementNoReports: {
    id: 'augur.elements.no_reports',
    defaultMessage: 'No reports selected.',
  },
  elementInvalidReportData: {
    id: 'augur.elements.invalid_report_data',
    defaultMessage: `Invalid report data. The json data might be corrupted or the specified key doesn't exist.`,
  },
  lineChartXLabel: {
    id: 'augur.elements.lineChart.xLabel',
    defaultMessage: 'X-Axis',
  },
  lineChartYLabel: {
    id: 'augur.elements.lineChart.yLabel',
    defaultMessage: 'Y-Axis',
  },
  barChartLabel: {
    id: 'augur.elements.barChart.label',
    defaultMessage: 'Label',
  },
  barChartLabelPlaceholder: {
    id: 'augur.elements.barChart.label.placeholder',
    defaultMessage: 'Enter legend label',
  },
  barChartColor: {
    id: 'augur.elements.barChart.color',
    defaultMessage: 'Color',
  },
  barChartColorPlaceholder: {
    id: 'augur.elements.barChart.color.placeholder',
    defaultMessage: 'Enter legend color',
  },
  channelsLabel: {
    id: 'augur.elements.channels.label',
    defaultMessage: 'Channels',
  },
  selectChannelElement: {
    id: 'augur.elements.channels.select',
    defaultMessage: 'Select Channels Element',
  },
  propensitiesLabel: {
    id: 'augur.elements.propensities.label',
    defaultMessage: 'Propensities',
  },
  selectPropensitiesElement: {
    id: 'augur.elements.propensities.select',
    defaultMessage: 'Select Propensities Element',
  },
  label: {
    id: 'augur.elements.label',
    defaultMessage: 'Label',
  },
  enterLabel: {
    id: 'augur.elements.label.enter',
    defaultMessage: 'Enter Label',
  },
  placeholder: {
    id: 'augur.elements.placeholder',
    defaultMessage: 'Placeholder',
  },
  value: {
    id: 'augur.elements.value',
    defaultMessage: 'Value',
  },
  enterValue: {
    id: 'augur.elements.value',
    defaultMessage: 'Value',
  },
  dependants: {
    id: 'augur.elements.dependants',
    defaultMessage: 'Dependants',
  },
  selectDependants: {
    id: 'augur.elements.dependants.select',
    defaultMessage: 'Select Dependants',
  },
});
